<template>
  <div class="terms">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="switch-cover">
      <div class="container">
        <div class="row">
          <div class="col-md-5 " >
            <div class="bg-white p-5 auth-card" >

              <form method="post" action="#" v-on:submit.prevent="validateCheckoutForm">

                  <div class="row">
                    <div class="col-md-6">
                      <RouterLink class="text-gray"  to="/login"> <img src="@/client/assets/images/left.png"> Back </RouterLink>
                    </div>
                    <div class="col-md-6">
                      <div style="float: right;">
                        Step 3 of 3 <br/> <span class="text-black text-bold"  > Forgot Password </span>
                      </div>
                    </div>
                  </div>
                  <div class="mb-5" style="clear: both;">

                  </div>

                  <div >
                    <h3>Create New Password</h3>
                  </div>

                  <div class="mb-5" style="font-size: 12px;">
                    Enter new password for your account
                  </div>

                  <div class="form-group mb-3">
                    <label :class="{ 'text-danger': $v.user.password.$error }"><strong>New Password</strong> </label> <br/>
                    <input v-model.trim="$v.user.password.$model" class="form-control" type="password"  />
                    <div class="text-danger" v-if="!$v.user.password.required && $v.user.password.$dirty">Password is required</div>
                    <div class="text-danger" v-if="!$v.user.password.minLength && $v.user.password.$dirty">Password must be between 8 to 32 characters  </div>
                  </div>

                  <div class="form-group mb-3">
                    <label :class="{ 'text-danger': $v.user.confirmPassword.$error }"><strong>Confirm Password</strong> </label> <br/>
                    <input v-model.trim="$v.user.confirmPassword.$model" class="form-control" type="password"  />
                    <div class="text-danger" v-if="!$v.user.confirmPassword.required && $v.user.confirmPassword.$dirty">Confirm password is required</div>
                    <div class="text-danger" v-if="!$v.user.confirmPassword.sameAsPassword && $v.user.confirmPassword.$dirty">Password should be the same as the above.</div>
                  </div>

                  <div class="mt-5 mb-5 ">
                    <button style="background-color: #E42B1A !important;color:#fff;" type="submit"  class=" btn-secondary btn  btn-lg  quote-btn btn-block">  <strong>Reset Password</strong>  </button>
                  </div>

              </form>


            </div>


          </div>
          <div class="col-md-7">

          </div>
        </div>
      </div>

    </div>



  </div>
</template>

<script>

  import { required, minLength, sameAs} from 'vuelidate/lib/validators'
  import { mapActions } from "vuex";
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name: 'home',
    data () {
      return {
        isLoading: false,
        fullPage: true,
        user:{
          email:"",
          password: "",
          confirmPassword:"",
          tokenId:""
        }
      };
    },
    mounted(){
      this.user.tokenId = this.$route.params.token;
      this.user.email = this.$route.params.email;

      console.log(this.user);
    },

    components: { Loading },

    validations: {
      user:{
        password:{required, minLength: minLength(8)},
        confirmPassword: {
            required,
          sameAsPassword: sameAs('password')
        }
      },
    },

    methods:{
      ...mapActions(["changePassword"]),

      submitResetPassword(){

        let self = this;

        this.isLoading = true;


        this.changePassword(this.user).then((response) => {
          self.isLoading = false;

          self.$toast.open({
            message: "Success!",
            type: 'success',
            duration:5000,
            position:"top-right"
          });

          self.$router.push("/login");


        }).catch(error => {
          self.isLoading = false;
          console.log(error);
        });

      },

      validateCheckoutForm(){
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.submitResetPassword()
        }
      },


    }
  }
</script>

<style>

  table td{
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .personal-info{
    min-height: 80vh;
    background-color: #F2F2F2;
  }


  .sso-sign-up a{
    border:1px solid #BDBDBD;
    padding: 15px;
    margin: 5px;
    border-radius: 4px;
  }
  .sso-sign-up img{
      height: 30px;
      width: 30px;
  }
   .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    color: #666666;
    font-size: 16px;
    font-weight: 600;
  }
  .nav-link:hover {
    border: none;
  }

  .personal-info button{
    width: 100%;
  }


</style>
